export interface MonitorFormValues {
    aspectRatio?: string;
    comments?: string;
    make?: string;
    model?: string;
    resolution?: string;
    serialNumber?: string;
    size?: string;
    type?: string;
}

export interface CustomFieldFormValues {
    index: number;
    fieldName?: string;
    fieldValue?: string;
}
export interface HddFormValues {
    capacity?: number;
    capacityUnit?: string;
    vendor?: string;
    model?: string;
    serialNumber?: string;
}

export enum TemplateTypeEnum {
    HDD = "HDD",
    Laptop = "Laptop",
    Mobiledevice = "Mobiledevice",
    Monitor = "Monitor",
    PC = "PC",
}

export const templateTypes = ["HDD", "Laptop", "Mobiledevice", "Monitor", "PC"] as const;
export type TemplateType = typeof templateTypes[number];

export const capacityUnits = ["B", "KB", "MB", "GB", "TB"] as const;
export type CapacityUnit = typeof capacityUnits[number];
