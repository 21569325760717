import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";

import style from "./license-events.scss";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import { useOutsideClick } from "components/header/MenuPanel";
import Checkmark from "components/icons/Checkmark";
import { createProductIdToNameMap, createSortedLicenseTypes, isSubscriptionLicense } from "components/licenses/common";
import LicenseEventsDatePicker from "components/licenses/license-history/LicenseEventsDatePicker";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_LICENSE_POOL_VIEW } from "domain/authority";
import { TABLE_PAGE_LIMIT } from "domain/globalConstants";
import {
    EventAction,
    EventType,
    FilterState,
    LicenseEvent,
    LicenseEventQuery,
    LicenseEventResult,
    TableState,
} from "domain/licenseEvent";
import { licenseEventsService } from "services/licenses/LicenseEventService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { tenantHasFeatureLicense } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { applyMode } from "store/theme";
import buttonStyle from "styles/buttons.scss";
import buttonsStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { logger } from "utils/logging";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});

interface LicenseEventsTableProps {
    filterState: FilterState;
    setFilterState: React.Dispatch<React.SetStateAction<FilterState>>;
    exportFailed: boolean;
    setExportFailed: (value: boolean) => void;
    filtersMissing: boolean;
    setFiltersMissing: (value: boolean) => void;
    tableState: TableState;
    setTableState: (value: TableState) => void;
}

const createAssetInfoCell = (value: string | undefined): JSX.Element => {
    return value ? (
        <div className={style.alignment}>
            <TextWithTooltip text={value} />
            <CopyToClipboard value={value} />
        </div>
    ) : (
        <></>
    );
};

const connector = connect(mapState, { applyMode });
type Props = ConnectedProps<typeof connector> & LicenseEventsTableProps;

const LicenseEventsTable: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(false);
    const [requestFailure, setRequestFailure] = React.useState();

    const ALL_PRODUCTS = createProductIdToNameMap();
    const ref = useOutsideClick(() => {
        setProductsFilterVisible(false);
        setActionsFilterVisible(false);
    });
    const productIdList = createSortedLicenseTypes().map((each) => each.productId);
    const [productsFilterVisible, setProductsFilterVisible] = React.useState(false);
    const [actionsFilterVisible, setActionsFilterVisible] = React.useState(false);
    const [refreshCount, setRefreshCount] = React.useState(0);
    const closeDropdown = () => {
        setProductsFilterVisible(false);
        setActionsFilterVisible(false);
    };

    const fetchData = () => {
        setLoading(true);
        setInitialLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const searchQuery: LicenseEventQuery = {
            search: props.filterState.quickSearchFilter,
            actions: props.filterState.eventTypes,
            productIds: props.filterState.productIds,
            from: props.filterState.startDate,
            to: props.filterState.endDate,
            cursor: props.tableState.cursor,
        };
        setRequestFailure(undefined);
        licenseEventsService
            .fetchLicenseEvents(searchQuery, abortController)
            .then((data: LicenseEventResult) => {
                props.setTableState({
                    ...props.tableState,
                    licenseEvents: props.tableState.licenseEvents.concat(data.licenseEvents),
                    scrollPosition: props.tableState.licenseEvents.length - 1,
                    cursor: data.cursor,
                });
            })
            .catch((errr) => {
                logger.error(errr);
                if (!abortController.signal.aborted) {
                    setRequestFailure(t("LicenseEvent.table.requestFailure"));
                }
            })
            .finally(() => {
                setLoading(false);
                setInitialLoading(false);
            });
    };

    const deduceLicenseChange = (type: string, amount: number) => {
        if (type.startsWith("feature") || isSubscriptionLicense(type)) {
            return t("Licenses.na");
        }
        return amount;
    };

    const deduceLicenseRemaining = (type: string, amount: number) => {
        if (type.startsWith("feature") || isSubscriptionLicense(type)) {
            return t("Licenses.subscription");
        }
        return amount !== null ? amount : 0;
    };

    const deduceEventTypeTranslation = (eventType: EventType) => {
        switch (eventType) {
            case "CONSUMED": {
                return t("LicenseEvent.table.eventType.consumed");
            }
            case "DELIVERED": {
                return t("LicenseEvent.table.eventType.delivered");
            }
            case "WITHDRAWN": {
                return t("LicenseEvent.table.eventType.withdrawn");
            }
            default: {
                throw Error("Unknown event type: " + eventType);
            }
        }
    };

    const deduceEventAction = (eventAction: EventAction) => {
        switch (eventAction) {
            case "LICENSE_CONSUMPTION":
                return t("LicenseEvent.table.eventAction.licenseConsumption");
            case "SECURE_LICENSE_CONSUMPTION":
                return t("LicenseEvent.table.eventAction.secureLicenseConsumption");
            case "FEATURE_LICENSE_CONSUMPTION":
                return t("LicenseEvent.table.eventAction.featureLicenseConsumption");
            case "LICENSE_KEY_CONSUMPTION":
                return t("LicenseEvent.table.eventAction.licenseKeyConsumption");
            case "LICENSE_DELIVERY":
                return t("LicenseEvent.table.eventAction.licenseDelivery");
            case "ENTITLEMENT_CREATION":
                return t("LicenseEvent.table.eventAction.entitlementCreation");
            case "LICENSE_KEY_CREATION":
                return t("LicenseEvent.table.eventAction.licenseKeyCreation");
            case "TENANT_DELETION":
                return t("LicenseEvent.table.eventAction.tenantDeletion");
            case "LICENSE_POOL_UPDATE":
                return t("LicenseEvent.table.eventAction.licensePoolUpdate");
            case "LICENSING_MODEL_CHANGE":
                return t("LicenseEvent.table.eventAction.licensingModelChange");
        }
        return "";
    };

    const clearTable = () => {
        setInitialLoading(false);
        setLoading(false);
        setRequestFailure(undefined);
    };

    const columns: Array<Column<LicenseEvent>> = [
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.eventDate")} key={"1"} />,
            accessor: "eventDate",
            Cell: ({ cell: { value } }) => <DateCell value={value} tooltip={true} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.product")} key={"2"} />,
            accessor: "productId",
            Cell: ({ row }) => <TextWithTooltip text={ALL_PRODUCTS.get(row.original.productId)} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.eventType.column")} key={"3"} />,
            accessor: "eventType",
            Cell: ({ row }) => <TextWithTooltip text={deduceEventTypeTranslation(row.original.eventType)} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.eventAction.column")} key={"4"} />,
            accessor: "eventAction",
            Cell: ({ row }) => <TextWithTooltip text={deduceEventAction(row.original.eventAction)} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.change")} key={"5"} />,
            accessor: "change",
            Cell: ({ row }) => (
                <TextWithTooltip text={deduceLicenseChange(row.original.productId, row.original.change)} />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.remaining")} key={"6"} />,
            accessor: "remaining",
            Cell: ({ row }) => (
                <TextWithTooltip text={deduceLicenseRemaining(row.original.productId, row.original.remaining)} />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.expirationDate")} key={"7"} />,
            accessor: "expirationDate",
            Cell: ({ cell: { value } }) => {
                return value ? <DateCell value={value} tooltip={true} /> : <></>;
            },
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.user")} key={"8"} />,
            accessor: "user",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.notes")} key={"9"} />,
            accessor: "notes",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.licenseConsumptionId")} key={"10"} />,
            accessor: "licenseConsumptionId",
            Cell: ({ cell: { value } }) => createAssetInfoCell(value),
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.assetId")} key={"11"} />,
            accessor: "assetId",
            Cell: ({ cell: { value } }) => createAssetInfoCell(value),
        },
    ];
    tenantHasFeatureLicense("FEATURE_LICENSE_POOLS") &&
        userSessionService.userHasAnyAuthority([AUTH_LICENSE_POOL_VIEW]) &&
        columns.push({
            Header: () => <TextWithTooltip text={t("LicenseEvent.table.licensePool")} key={"12"} />,
            accessor: "poolName",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        });

    React.useEffect(() => {
        setInitialLoading(true);
        props.setTableState({
            licenseEvents: [],
            cursor: undefined,
            scrollPosition: 0,
        });
        setRefreshCount(refreshCount + 1);
    }, [
        props.filterState.quickSearchFilter,
        props.filterState.startDate,
        props.filterState.endDate,
        props.filterState.eventTypes,
        props.filterState.productIds,
    ]);
    React.useEffect(() => {
        if (props.filterState.productIds.length > 0 && props.filterState.eventTypes.length > 0) {
            fetchData();
        } else {
            clearTable();
        }
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [refreshCount]);

    const handleStartDateChange = (date: string) => {
        handleFilterChanges({ startDate: date });
    };

    const handleEndDateChange = (date: string) => {
        handleFilterChanges({ endDate: date });
    };

    const handleSearchQueryChange = (search: string) => {
        handleFilterChanges({ quickSearchFilter: search });
    };

    const handleProductIdsChange = (productIds: string[]) => {
        handleFilterChanges({ productIds: productIds });
    };

    const handleEventTypesChange = (eventTypes: EventType[]) => {
        handleFilterChanges({ eventTypes: eventTypes });
    };

    const handleFilterChanges = (update: Partial<FilterState>) => {
        props.setFilterState((prevState) => ({
            ...prevState,
            ...update,
        }));
    };

    const createResultsSummarySection = () => {
        return (
            !loading && (
                <div
                    data-testid={testIds.common.primaryView.table.resultSummaryContainer}
                    onClick={() => closeDropdown}
                >
                    {props.tableState.licenseEvents.length > 0
                        ? t("Common.defaultSearchResultHint", { dataCount: props.tableState.licenseEvents.length })
                        : t("Common.noSearchResultHint")}
                </div>
            )
        );
    };

    const createProductsFilter = () => {
        return (
            <>
                <div
                    className={classNames(formStyle.select, style.selectSearch, style.commonHeight, style.extendWidth)}
                    onClick={() => {
                        setProductsFilterVisible(!productsFilterVisible);
                        setActionsFilterVisible(false);
                        productsFilterVisible &&
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_EVENTS,
                                action: Action.FILTER_BY_PRODUCT,
                            });
                    }}
                    data-testid={testIds.workArea.license.licenseEvents.productsFilterSelect}
                >
                    {props.filterState.productIds.length === ALL_PRODUCTS.size
                        ? t("LicenseEvent.filter.product.all")
                        : props.filterState.productIds.length === 1
                        ? ALL_PRODUCTS.get(props.filterState.productIds[0])
                        : props.filterState.productIds.length <= 0
                        ? t("LicenseEvent.filter.product.none")
                        : t("LicenseEvent.filter.product.amount", { amount: props.filterState.productIds.length })}
                </div>

                <ul hidden={!productsFilterVisible} className={classNames(style.filters, style.extendWidth)}>
                    {Array.from(productIdList).map((product) => {
                        return (
                            <li
                                key={product}
                                onClick={() => {
                                    handleProductIdsChange(
                                        props.filterState.productIds.includes(product)
                                            ? props.filterState.productIds.filter((each) => each !== product)
                                            : props.filterState.productIds.concat(product)
                                    );
                                    closeDropdown();
                                }}
                            >
                                <div className={style.filteringItem}>
                                    <div className={style.checkIcon}>
                                        {props.filterState.productIds.includes(product) && (
                                            <Checkmark color={props.theme.iconFillColor} />
                                        )}
                                    </div>
                                    <div>{ALL_PRODUCTS.get(product)} </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div>
                    <a
                        className={
                            props.filterState.productIds.length === ALL_PRODUCTS.size
                                ? style.disabled
                                : style.nameLinkContainer
                        }
                        onClick={() => {
                            handleProductIdsChange(productIdList);
                        }}
                    >
                        {t("LicenseEvent.filter.product.selectAll")}
                    </a>
                    <a
                        className={props.filterState.productIds.length === 0 ? style.disabled : style.nameLinkContainer}
                        onClick={() => {
                            handleProductIdsChange([]);
                        }}
                    >
                        {t("LicenseEvent.filter.product.selectNone")}
                    </a>
                </div>
            </>
        );
    };
    const createActionFilter = () => {
        const actions: EventType[] = ["DELIVERED", "CONSUMED", "WITHDRAWN"];
        return (
            <>
                <div
                    className={classNames(formStyle.select, style.selectSearch, style.commonHeight)}
                    onClick={() => {
                        setProductsFilterVisible(false);
                        setActionsFilterVisible(!actionsFilterVisible);
                        actionsFilterVisible &&
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_EVENTS,
                                action: Action.FILTER_BY_ACTION,
                            });
                    }}
                    data-testid={testIds.workArea.license.licenseEvents.actionsFilterSelect}
                >
                    {props.filterState.eventTypes.length === actions.length
                        ? t("LicenseEvent.filter.action.all")
                        : props.filterState.eventTypes.length <= 0
                        ? t("LicenseEvent.filter.action.none")
                        : props.filterState.eventTypes.length === 1
                        ? deduceEventTypeTranslation(props.filterState.eventTypes[0])
                        : t("LicenseEvent.filter.action.amount", { amount: props.filterState.eventTypes.length })}
                </div>

                <ul hidden={!actionsFilterVisible} className={style.filters}>
                    {actions.map((action: EventType) => {
                        return (
                            <li
                                key={action}
                                onClick={() => {
                                    handleEventTypesChange(
                                        props.filterState.eventTypes.includes(action)
                                            ? props.filterState.eventTypes.filter((each) => each !== action)
                                            : props.filterState.eventTypes.concat(action)
                                    );
                                    closeDropdown();
                                }}
                            >
                                <div className={style.filteringItem}>
                                    <div className={style.checkIcon}>
                                        {props.filterState.eventTypes.includes(action) && (
                                            <Checkmark color={props.theme.iconFillColor} />
                                        )}
                                    </div>
                                    <div>{deduceEventTypeTranslation(action)} </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    return (
        <>
            <div className={style.tableWrapper} ref={ref} onClick={() => closeDropdown}>
                <div className={classNames(style.count, style.paddingBottom)}>
                    <div>{createResultsSummarySection()}</div>
                    <div className={style.searchFilters}>
                        <div className={formStyle.search}>{createProductsFilter()}</div>
                        <div className={formStyle.search}>{createActionFilter()}</div>
                        <div ref={ref} onClick={() => closeDropdown}>
                            <LicenseEventsDatePicker from={handleStartDateChange} to={handleEndDateChange} />
                        </div>
                        <div className={formStyle.search} ref={ref} onClick={() => closeDropdown}>
                            <SearchView
                                setSearch={handleSearchQueryChange}
                                searchInProgress={false}
                                tooltip={t("LicenseEvent.search.placeholder")}
                            />
                        </div>
                    </div>
                </div>
                <div className={layoutStyle.tableWrapper} onClick={() => closeDropdown}>
                    <Table
                        tableIdentity={RepositoryKey.LICENSE_EVENTS_TABLE}
                        data={props.tableState.licenseEvents}
                        columns={columns}
                        loaded={!initialLoading}
                        failureMessage={requestFailure}
                        tooltips={true}
                        scrollTo={props.tableState.scrollPosition}
                        emptyMessage={t("LicenseEvent.table.emptyStateMessage")}
                        testId={testIds.workArea.license.licenseEvents.table.itself}
                    />
                </div>

                <div>
                    {props.tableState.cursor != null &&
                        props.tableState.licenseEvents.length >= TABLE_PAGE_LIMIT &&
                        props.tableState.licenseEvents.length != 0 &&
                        !requestFailure &&
                        (loading ? (
                            <LoadingIndicator small={true} />
                        ) : (
                            <button
                                className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                                onClick={() => {
                                    fetchData();
                                    usageStatisticsService.sendEvent({
                                        action: Action.LOAD_MORE,
                                        category: Category.LICENSE_EVENTS,
                                    });
                                }}
                                data-testid={testIds.common.primaryView.table.loadMoreButton}
                            >
                                {t("Common.loadMore")}
                            </button>
                        ))}
                </div>
            </div>
            {props.exportFailed ? (
                <Modal
                    isOpen={props.exportFailed}
                    hideModal={() => {
                        props.setExportFailed(false);
                        props.setFiltersMissing(false);
                    }}
                    modalTitle={t("LicenseEvent.export.tooltip")}
                >
                    <div className={formStyle.resultContainer}>
                        {props.filtersMissing
                            ? t("LicenseEvent.export.filtersMissingErrorMessage")
                            : t("Common.errorHasOccurredMessage")}
                    </div>
                    <div className={formStyle.okButtonContainer}>
                        <button
                            className={classNames(buttonsStyle.primaryButton, buttonsStyle.medium)}
                            onClick={() => {
                                props.setExportFailed(false);
                                props.setFiltersMissing(false);
                            }}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
            ) : null}
        </>
    );
};

export default connector(LicenseEventsTable);
