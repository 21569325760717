import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import ImportWorkflowTemplateView from "./ImportWorkflowTemplateView";
import ManageWorkflowTemplateView from "./ManageWorkflowTemplateView";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { WORKFLOWS_ROUTE } from "components/router/Routes";
import { WorkflowTemplatesManagement } from "components/workflows/workflow-templates/WorkflowTemplatesManagement";
import { AUTH_WORKFLOW_TEMPLATE_CREATE } from "domain/authority";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
});
const connector = connect(mapState);

const WorkflowTemplatesManagementView = (props: ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [count, setCount] = React.useState<number>(0);

    function updateState() {
        setCount((prev) => ++prev);
    }
    function deleteState() {
        setCount((prev) => --prev);
    }
    const viewActions = userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_CREATE]) ? (
        <>
            <ImportWorkflowTemplateView user={props.user} onUpdate={updateState} />
            <ManageWorkflowTemplateView user={props.user} onUpdate={updateState} />
        </>
    ) : undefined;

    const layoutProps = {
        viewTitle: t("workflowTemplatesManager.name"),
        view: <WorkflowTemplatesManagement setCount={setCount} count={count} onDelete={deleteState} />,
        mainRoute: WORKFLOWS_ROUTE,
        viewActions,
        enableRefresh: true,
        onHandleRefresh: updateState,
    };
    return <ApplicationLayout {...layoutProps} />;
};
export default connector(WorkflowTemplatesManagementView);
