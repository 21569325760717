import { TableData } from "./table";

export const events = ["CONSUMED", "DELIVERED", "WITHDRAWN"];
export type EventType = typeof events[number];

export const actions = [
    "LICENSE_CONSUMPTION",
    "SECURE_LICENSE_CONSUMPTION",
    "FEATURE_LICENSE_CONSUMPTION",
    "LICENSE_KEY_CONSUMPTION",
    "LICENSE_DELIVERY",
    "ENTITLEMENT_CREATION",
    "LICENSE_KEY_CREATION",
];
export type EventAction = typeof actions[number];

export interface LicenseEvent {
    eventUuid: string;
    eventDate: string;
    productId: string;
    eventType: EventType;
    change: number;
    remaining: number;
    expirationDate: string;
    user: string;
    poolName: string;
    eventAction: EventAction;
    notes?: string;
    licenseConsumptionId?: string;
    assetId?: string;
}

export interface LicenseEventQuery {
    search: string;
    from: string;
    to: string;
    productIds: string[];
    actions: string[];
    cursor?: KeyCursor;
}

export interface KeyCursor {
    eventUuid: string;
    tenantUuid: string;
    time: string;
}

export interface LicenseEventResult extends TableData {
    licenseEvents: LicenseEvent[];
    cursor?: KeyCursor;
}

export interface TableState {
    licenseEvents: LicenseEvent[];
    cursor?: KeyCursor;
    scrollPosition: number;
}

export interface FilterState {
    startDate: string;
    endDate: string;
    eventTypes: EventType[];
    quickSearchFilter: string;
    productIds: string[];
}
