import classNames from "classnames";
import * as React from "react";
import { SetStateAction } from "react";
import { Menu } from "react-aria-menubutton";
import { FileDrop } from "react-file-drop";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Column } from "react-table";
import { toast } from "react-toastify";

import style from "./workflow-templates-table.scss";
import WorkflowTemplateKebabMenu from "./WorkflowTemplatesKebabMenu";
import AlertDialog from "components/alert-dialog/AlertDialog";
import ToggleablePanel from "components/header/ToggleablePanel";
import Checkmark from "components/icons/Checkmark";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import EmptyStateIcon from "components/icons/EmptyState";
import { SuccessIcon } from "components/icons/SuccessIcon";
import KebabMenu from "components/kebab-menu/KebabMenu";
import layout from "components/layout/layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import workflowStyle from "components/workflows/all-workflows-table.scss";
import ConnectedWorkflowsList from "components/workflows/ConnectedWorkflowsList";
import ImportWorkflowsDialog from "components/workflows/ImportWorkflowsDialog";
import ProductNameColumn from "components/workflows/ProductNameColumn";
import WorkflowEventDatePicker from "components/workflows/WorkflowEventDatePicker";
import { compareEditorVersionDtos } from "components/workflows/WorkflowUtil";
import {
    AUTH_WORKFLOW_TEMPLATE_DELETE,
    AUTH_WORKFLOW_TEMPLATE_EDIT,
    AUTH_WORKFLOW_TEMPLATE_EXPORT,
    AUTH_WORKFLOW_TEMPLATE_PUBLISH,
} from "domain/authority";
import { FeatureLicenseType } from "domain/users";
import { FilterState } from "domain/workflows";
import { TemplateTableData } from "domain/workflowTemplates";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userService } from "services/user/users/UserService";
import { userSessionService } from "services/user/UserSessionService";
import { PublishStatus, templateService } from "services/workflows/TemplateService";
import {
    ManifestWorkflowEditor,
    Profile,
    profileToFeatureLicenseMap,
    profileToNameMap,
    workflowService,
} from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { setUser } from "store/user";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import form from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import statusStyle from "styles/status.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
    theme: state.themeReducer.theme,
});
const connector = connect(mapState, { setUser });

interface TableState {
    workflowTemplatesData: TemplateTableData[];
    count: number;
    cursor: string;
    scrollPosition?: number;
}

interface UserList {
    name: string;
    uuid: string;
}

interface PublishState {
    uuid: string;
    name: string;
    publishStatus: string;
    message: string;
    error: boolean;
    loading: boolean;
    setPublished?: React.Dispatch<SetStateAction<boolean>> | undefined;
    setMenuHidden?: React.Dispatch<SetStateAction<boolean>> | undefined;
}

const WorkflowTemplatesTable = (
    props: ConnectedProps<typeof connector> & {
        isSubWorkflow: boolean;
        count: number;
        setCount: React.Dispatch<React.SetStateAction<number>>;
        search: string;
        filterState?: FilterState;
        setFilterState?: React.Dispatch<React.SetStateAction<FilterState>>;
        tableIdentity: RepositoryKey;
        onDelete: () => void;
    }
): JSX.Element => {
    {
        const { t } = useTranslation();
        const [loading, setLoading] = React.useState<boolean>(false);
        const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
        const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
        const [workflowEditors, setWorkflowEditors] = React.useState<ManifestWorkflowEditor[]>([]);
        const [userList, setUsersList] = React.useState<UserList[]>([]);

        const [tableState, setTableState] = React.useState<TableState>({
            workflowTemplatesData: [],
            count: 0,
            cursor: "",
            scrollPosition: 0,
        });
        const tableContainerRef = React.useRef<HTMLDivElement>(null);
        const [filterProductType, setFilterProductType] = React.useState("");
        const [filterPublishStatus, setFilterPublishStatus] = React.useState("");
        const [filterProduct, setFilterProduct] = React.useState("");
        const [filterCreator, setFilterCreator] = React.useState("");
        const [optionList, setOptionList] = React.useState<JSX.Element[] | undefined>(undefined);
        const [search, setSearchQuery] = React.useState("");
        const { current: abortControllers } = React.useRef<AbortController[]>([]);
        const [publishState, setPublishState] = React.useState<PublishState>({
            uuid: "",
            name: "",
            publishStatus: "",
            message: "",
            error: false,
            loading: false,
        });
        const [uploadTemplateDialogVisible, setUploadTemplateDialogVisible] = React.useState(false);
        const [uploadWorkflowFileList, setUploadWorkflowFileList] = React.useState<FileList | undefined>(undefined);
        const [uploadTemplateModalTitle, setUploadTemplateModalTitle] = React.useState<string>(
            t("workflowTemplatesManager.ImportWorkflowTemplateDialog.title")
        );
        const [uploadTemplateModalIcon, setUploadTemplateModalIcon] = React.useState<JSX.Element | undefined>(
            undefined
        );
        const theme = useSelector((state: StoreState) => state.themeReducer.theme);

        const handleStartDateChange = (date: string) => {
            handleFilterChanges({ from: date });
        };
        const handleEndDateChange = (date: string) => {
            handleFilterChanges({ to: date });
        };
        const versionValue = filterProductType.includes("Erasure")
            ? filterProductType.replace("Erasure", "")
            : filterProductType.replace("Diagnostics", "");

        const handleFilterChanges = (update: Partial<FilterState>) => {
            if (props.setFilterState) {
                props.setFilterState((prevState) => ({
                    ...prevState,
                    ...update,
                }));
            }
        };
        const handlePublishedChanged = (
            uuid: string,
            name: string,
            publishStatus: string,
            setPublished: React.Dispatch<React.SetStateAction<boolean>> | undefined,
            setMenuHidden: React.Dispatch<React.SetStateAction<boolean>> | undefined
        ) => {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            setPublishState((prevState) => ({
                ...prevState,
                uuid: uuid,
                name: name,
                setPublished: setPublished,
                publishStatus: publishStatus,
                loading: true,
            }));
            const newStatus = PublishStatus.PUBLISHED === publishStatus;
            const message = newStatus
                ? t("workflowTemplatesManager.publish.publishSuccessMessage", { name: name })
                : t("workflowTemplatesManager.publish.unpublishSuccessMessage", { name: name });
            if (setMenuHidden) {
                setMenuHidden(true);
            }
            templateService
                .publish(uuid, newStatus, abortController)
                .then(() => {
                    if (setPublished) {
                        setPublished(newStatus);
                    }
                    setPublishState((prevState) => ({
                        ...prevState,
                        error: false,
                    }));
                    toast(
                        <SuccessIcon
                            successClass={layout.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={message}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layout.customToastSuccessMessage,
                        }
                    );
                })
                .catch(() => {
                    if (setPublished) {
                        setPublished(!newStatus);
                    }
                    setPublishState((prevState) => ({
                        ...prevState,
                        message: t("workflowTemplatesManager.publish.publishingFailed"),
                        error: true,
                    }));
                })
                .finally(() => {
                    setPublishState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                    if (setMenuHidden) {
                        setMenuHidden(false);
                    }
                });
        };
        const hasPublishAuthority = userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_PUBLISH]);
        const columns: Array<Column<TemplateTableData>> = props.isSubWorkflow
            ? [
                  {
                      Header: () => <TextWithTooltip text={t("workflowTemplatesManager.subWorkflows.name")} key="1" />,
                      accessor: "name",
                      Cell: (cellInfo) => (
                          <>
                              {userSessionService.userHasAnyAuthority([
                                  AUTH_WORKFLOW_TEMPLATE_EDIT,
                                  AUTH_WORKFLOW_TEMPLATE_EXPORT,
                                  AUTH_WORKFLOW_TEMPLATE_DELETE,
                              ]) && (
                                  <KebabMenu>
                                      <WorkflowTemplateKebabMenu
                                          uuid={cellInfo.cell.row.original.uuid}
                                          name={cellInfo.cell.row.original.name}
                                          connectedWorkflows={cellInfo.row.original.connectedWorkflows}
                                          onDelete={props.onDelete}
                                          isSubWorkflow={props.isSubWorkflow}
                                      />
                                  </KebabMenu>
                              )}
                              <button className={style.templateNameCell}>
                                  <TextWithTooltip text={cellInfo.value} />
                              </button>
                          </>
                      ),
                      width: deriveColumnWidth(35, tableContainerRef),
                  },
                  {
                      Header: () => (
                          <TextWithTooltip text={t("workflowTemplatesManager.subWorkflows.product")} key="2" />
                      ),
                      accessor: "profile",
                      Cell: ({ cell: { row } }) => (
                          <ProductNameColumn
                              profile={row.original.profile.toLowerCase()}
                              version={row.original.editorVersion}
                              generation={row.original.editorGeneration}
                              t={t}
                          />
                      ),
                      width: deriveColumnWidth(35, tableContainerRef),
                  },
                  {
                      Header: () => (
                          <TextWithTooltip text={t("workflowTemplatesManager.subWorkflows.templates")} key="3" />
                      ),
                      accessor: "connectedWorkflows",
                      Cell: ({ cell: { value } }) => {
                          return value.totalConnectedWorkflows ? (
                              <ConnectedWorkflowsList connectedWorkflows={value} isSubWorkflow={true} />
                          ) : (
                              <div className={style.alignRight}>-</div>
                          );
                      },
                      width: deriveColumnWidth(35, tableContainerRef),
                  },
                  {
                      Header: () => <TextWithTooltip text={t("Common.creationDate")} key="4" />,
                      accessor: "created",
                      Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
                      width: deriveColumnWidth(35, tableContainerRef),
                  },
                  {
                      Header: () => (
                          <TextWithTooltip text={t("workflowTemplatesManager.subWorkflows.creator")} key="5" />
                      ),
                      accessor: "userName",
                      Cell: (cellInfo) => <TextWithTooltip text={cellInfo.value} />,
                      width: deriveColumnWidth(35, tableContainerRef),
                  },
              ]
            : [
                  {
                      Header: () => <TextWithTooltip text={t("workflowTemplatesManager.template.name")} key="1" />,
                      accessor: "name",
                      Cell: (cellInfo) => (
                          <>
                              {userSessionService.userHasAnyAuthority([
                                  AUTH_WORKFLOW_TEMPLATE_EDIT,
                                  AUTH_WORKFLOW_TEMPLATE_EXPORT,
                                  AUTH_WORKFLOW_TEMPLATE_DELETE,
                              ]) && (
                                  <KebabMenu>
                                      <WorkflowTemplateKebabMenu
                                          uuid={cellInfo.cell.row.original.uuid}
                                          name={cellInfo.cell.row.original.name}
                                          connectedWorkflows={cellInfo.row.original.connectedWorkflows}
                                          onDelete={props.onDelete}
                                          isSubWorkflow={props.isSubWorkflow}
                                      />
                                  </KebabMenu>
                              )}
                              <button className={style.templateNameCell}>
                                  <TextWithTooltip text={cellInfo.value} />
                              </button>
                          </>
                      ),
                      width: deriveColumnWidth(20, tableContainerRef),
                  },
                  {
                      Header: () => <TextWithTooltip text={t("workflowTemplatesManager.template.product")} key="4" />,
                      accessor: "profile",
                      Cell: ({ cell: { row } }) => (
                          <ProductNameColumn
                              profile={row.original.profile.toLowerCase()}
                              version={row.original.editorVersion}
                              generation={row.original.editorGeneration}
                              t={t}
                          />
                      ),
                      width: deriveColumnWidth(32, tableContainerRef),
                  },
                  {
                      Header: () => <TextWithTooltip text={t("Common.status")} key="3" />,
                      accessor: "published",
                      Cell: (cellInfo) => {
                          return (
                              <PublishBadge
                                  published={cellInfo.value}
                                  uuid={cellInfo.row.original.uuid}
                                  name={cellInfo.row.original.name}
                                  handlePublishedChanged={handlePublishedChanged}
                                  hasAuthority={hasPublishAuthority}
                              />
                          );
                      },
                      width: deriveColumnWidth(20, tableContainerRef),
                  },
                  {
                      Header: () => (
                          <TextWithTooltip text={t("workflowTemplatesManager.template.usageCount")} key="8" />
                      ),
                      accessor: "usageCount",
                      Cell: (cellInfo) => (
                          <div className={style.alignRight}>
                              <TextWithTooltip text={cellInfo.value} />
                          </div>
                      ),
                      width: deriveColumnWidth(15, tableContainerRef),
                  },
                  {
                      Header: () => <TextWithTooltip text={t("workflowTemplatesManager.subWorkflows.title")} key="2" />,
                      accessor: "connectedWorkflows",
                      Cell: ({ cell: { value } }) => {
                          return value.totalConnectedWorkflows > 0 ? (
                              <ConnectedWorkflowsList connectedWorkflows={value} isSubWorkflow={false} />
                          ) : (
                              <div className={style.alignRight}>-</div>
                          );
                      },
                      width: deriveColumnWidth(20, tableContainerRef),
                  },

                  {
                      Header: () => <TextWithTooltip text={t("workflowTemplatesManager.template.createdBy")} key="5" />,
                      accessor: "userName",
                      Cell: (cellInfo) => <TextWithTooltip text={cellInfo.value} />,
                      width: deriveColumnWidth(23, tableContainerRef),
                  },
                  {
                      Header: () => <TextWithTooltip text={t("Common.creationDate")} key="6" />,
                      accessor: "created",
                      Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
                      width: deriveColumnWidth(22, tableContainerRef),
                  },

                  {
                      Header: () => (
                          <TextWithTooltip text={t("workflowTemplatesManager.template.lastModified")} key="7" />
                      ),
                      accessor: "modified",
                      Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
                      width: deriveColumnWidth(22, tableContainerRef),
                  },
              ];

        const fetchData = (initialLoading: boolean, abortController: AbortController) => {
            setLoading(true);
            setInitialLoading(initialLoading);
            templateService
                .fetchWorkflowTemplates(
                    search,
                    initialLoading ? "" : tableState.cursor,
                    filterProduct.toUpperCase(),
                    filterPublishStatus,
                    versionValue.trimEnd(),
                    props.filterState?.from,
                    props.filterState?.to,
                    props.isSubWorkflow,
                    filterCreator,
                    abortController
                )
                .then((data) => {
                    setTableState((prevState) => ({
                        ...prevState,
                        scrollPosition: prevState.workflowTemplatesData.length - 1,
                        workflowTemplatesData: prevState.workflowTemplatesData.concat(data.workflowTemplatesTableData),
                        count: data.count,
                        cursor: data.cursor,
                    }));
                    setLoading(false);
                    setRequestFailureMessage("");
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        setRequestFailureMessage(
                            t(
                                props.isSubWorkflow
                                    ? "workflowTemplatesManager.template.requestFailedSubWorkflow"
                                    : "workflowTemplatesManager.template.requestFailed"
                            )
                        );
                    }
                })
                .finally(() => {
                    if (!abortController.signal.aborted) {
                        setLoading(false);
                        setInitialLoading(false);
                    }
                });
        };

        React.useEffect(() => {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            workflowService.fetchWorkflowEditors(Profile.ALL, abortController).then((workflowEditors) => {
                setWorkflowEditors(workflowEditors);
            });
            return () => {
                abortControllers.forEach((abortController) => abortController.abort());
            };
        }, []);

        React.useEffect(() => {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            userService.fetchAllUsers(abortController, "true").then((data) => {
                setUsersList(
                    data.userTableData
                        .map((each) => {
                            return { name: each.name ?? each.email, uuid: each.uuid };
                        })
                        .sort((a, b) => a.name.localeCompare(b.name))
                );
            });
            return () => {
                abortControllers.forEach((abortController) => abortController.abort());
            };
        }, []);

        React.useEffect(() => {
            const abortController = new AbortController();
            setUploadTemplateModalTitle(t("workflowTemplatesManager.ImportWorkflowTemplateDialog.title"));
            setTableState({ workflowTemplatesData: [], count: 0, cursor: "", scrollPosition: 0 });
            fetchData(true, abortController);
            return () => {
                abortController.abort();
            };
        }, [
            props.count,
            search,
            filterProduct.toUpperCase(),
            filterProductType,
            filterPublishStatus,
            props.filterState?.from,
            props.filterState?.to,
            filterCreator,
        ]);

        React.useEffect(() => {
            const sortedEditors = workflowEditors
                .filter((editor) =>
                    filterProduct == Profile.BDE ? editor.profile === filterProduct : editor.profile != Profile.BDE
                )
                .sort(compareEditorVersionDtos);

            const removeDuplicateVersion = sortedEditors.filter(
                (obj, index, self) => index === self.findIndex((each) => each.version === obj.version)
            );

            setOptionList(
                removeDuplicateVersion.map((editor) => (
                    <option key={editor.version + ":" + editor.profile}>{editor.version}</option>
                ))
            );
        }, [filterProduct]);
        let dataCount = null;
        if (tableState.workflowTemplatesData.length > 0) {
            dataCount = t("Common.recordsCount", { dataCount: tableState.workflowTemplatesData.length });
        }

        const resetPublishState = () => {
            setPublishState(() => ({
                uuid: "",
                name: "",
                publishStatus: "",
                message: "",
                error: false,
                loading: false,
            }));
        };

        const showUploadTemplateDialog = (fileList: FileList) => {
            setUploadWorkflowFileList(fileList);
            setUploadTemplateDialogVisible(true);
        };

        const showUploadSubWorkflowDialog = (fileList: FileList) => {
            setUploadTemplateModalTitle(t("workflowTemplatesManager.ImportSubWorkflowTemplateDialog.title"));
            setUploadWorkflowFileList(fileList);
            setUploadTemplateDialogVisible(true);
        };

        const hideUploadTemplateDialog = () => {
            setUploadTemplateModalTitle(t("workflowTemplatesManager.ImportWorkflowTemplateDialog.title"));
            setUploadTemplateModalIcon(undefined);
            setUploadTemplateDialogVisible(false);
            setUploadWorkflowFileList(undefined);
            props.setCount((prev) => ++prev);
        };

        const handleFileDrop = (files: FileList) => {
            if (files.length === 0) {
                return;
            }
            usageStatisticsService.sendEvent({
                category: Category.WORKFLOW,
                action: Action.IMPORT_WORKFLOW,
            });
            if (props.isSubWorkflow) {
                showUploadSubWorkflowDialog(files);
            } else {
                showUploadTemplateDialog(files);
            }
        };

        return (
            <>
                <div className={layoutStyle.tableWrapper}>
                    <div className={layoutStyle.aboveTable}>
                        <div className={layoutStyle.recordCount}>{dataCount}</div>
                        <div className={workflowStyle.filter}>
                            <span className={workflowStyle.label}>{t("WorkflowsTable.filter.filterBy")}</span>
                            <div className={formStyle.search}>
                                <select
                                    id={"product"}
                                    value={filterProduct}
                                    onChange={(event) => {
                                        setFilterProduct(event.target.value);
                                        setFilterProductType("");
                                    }}
                                    className={classNames(formStyle.select, formStyle.fixedWidthInput)}
                                    data-testid={
                                        testIds.workArea.workflowTemplatesManager.primaryView.filter.productSelect
                                    }
                                >
                                    <option key={"empty"} value={""}>
                                        {t("workflowTemplatesManager.filter.allProducts")}
                                    </option>
                                    {Object.values(Profile).map((each, index) => {
                                        if (
                                            Profile.ALL !== each &&
                                            props.tenantDetails?.featureLicenses.includes(
                                                profileToFeatureLicenseMap.get(each) as FeatureLicenseType
                                            )
                                        ) {
                                            return (
                                                <option key={index} value={each}>
                                                    {profileToNameMap.get(each)}
                                                </option>
                                            );
                                        }
                                    })}
                                </select>
                            </div>
                            <div className={formStyle.search}>
                                <select
                                    name="version"
                                    value={filterProductType}
                                    onChange={(e) => setFilterProductType(e.target.value)}
                                    className={classNames(form.select, workflowStyle.fixedWidthVersion)}
                                    disabled={filterProduct === ""}
                                    data-testid={
                                        testIds.workArea.workflowTemplatesManager.primaryView.filter.versionSelect
                                    }
                                >
                                    <option key={"value"} value={""}>
                                        {t("workflowTemplatesManager.filter.allVersions")}
                                    </option>
                                    {optionList}
                                </select>
                            </div>
                            {!props.isSubWorkflow && (
                                <div className={formStyle.search}>
                                    <select
                                        name="published"
                                        value={filterPublishStatus}
                                        onChange={(e) => setFilterPublishStatus(e.target.value)}
                                        className={classNames(form.select, workflowStyle.fixedWidthVersion)}
                                        data-testid={
                                            testIds.workArea.workflowTemplatesManager.primaryView.filter.statusSelect
                                        }
                                    >
                                        <option key={"value"} value={""}>
                                            {t("workflowTemplatesManager.filter.allStatuses")}
                                        </option>
                                        {Object.values(PublishStatus).map((each, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={PublishStatus.PUBLISHED == each ? "true" : "false"}
                                                >
                                                    {each}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            )}
                            <div className={formStyle.search}>
                                {}
                                <select
                                    name="creator"
                                    value={filterCreator}
                                    onChange={(e) => setFilterCreator(e.target.value)}
                                    className={classNames(form.select, workflowStyle.fixedWidthVersion)}
                                    data-testid={
                                        testIds.workArea.workflowTemplatesManager.primaryView.filter.creatorSelect
                                    }
                                >
                                    <option key={"value"} value={""}>
                                        {t("workflowTemplatesManager.filter.allCreators")}
                                    </option>
                                    {Object.values(userList).map((each, index) => {
                                        return (
                                            <option key={index} value={each.uuid}>
                                                {each.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div>
                                <WorkflowEventDatePicker from={handleStartDateChange} to={handleEndDateChange} />
                            </div>
                            <div className={formStyle.search}>
                                <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                            </div>
                        </div>
                    </div>
                    <FileDrop onDrop={handleFileDrop}>
                        <div
                            className={classNames(
                                loading && initialLoading && tableState.workflowTemplatesData.length < 1
                                    ? workflowStyle.loadingIndicator
                                    : "",
                                workflowStyle.tableStyle
                            )}
                        >
                            {tableState.workflowTemplatesData.length > 0 || initialLoading ? (
                                <Table
                                    tableIdentity={props.tableIdentity}
                                    data={tableState.workflowTemplatesData}
                                    columns={columns}
                                    loaded={!initialLoading}
                                    loading={loading}
                                    failureMessage={requestFailureMessage}
                                    tooltips={true}
                                    scrollTo={tableState.scrollPosition}
                                    emptyMessage={t("workflowTemplatesManager.template.emptyStateMessage")}
                                    data-testId={testIds.workArea.workflowTemplatesManager.primaryView.table}
                                    noAction={true}
                                />
                            ) : (
                                <div className={style.errorMessage}>
                                    <EmptyStateIcon
                                        ellipseBackgroundColor={props.theme.emptyStateEllipseBackgroundColor}
                                        biggestCircleBackgroundColor={
                                            props.theme.emptyStateBiggestCircleBackgroundColor
                                        }
                                        smallestCircleBackgroundColor={
                                            props.theme.emptyStateSmallestCircleBackgroundColor
                                        }
                                        personBackgroundColor={props.theme.emptyStatePersonColor}
                                        personShirtColor={props.theme.emptyStatePersonShirtColor}
                                        personSleeveColor={props.theme.emptyStatePersonSleeveColor}
                                        ellipseBackgroundOpacity={props.theme.emptyStateEllipseBackgroundOpacity}
                                        biggestCircleBackgroundOpacity={
                                            props.theme.emptyStateBiggestCircleBackgroundOpacity
                                        }
                                        smallestCircleBackgroundOpacityFirst={
                                            props.theme.emptyStateSmallestCircleBackgroundOpacityFirst
                                        }
                                        smallestCircleBackgroundOpacitySecond={
                                            props.theme.emptyStateSmallestCircleBackgroundOpacitySecond
                                        }
                                        smallestCircleBackgroundOpacityThird={
                                            props.theme.emptyStateSmallestCircleBackgroundOpacityThird
                                        }
                                    />
                                    <div className={style.errorMessageText}>
                                        <TextBlock disableBottomSpacing={false}>
                                            {requestFailureMessage !== undefined && requestFailureMessage != ""
                                                ? t(
                                                      props.isSubWorkflow
                                                          ? "workflowTemplatesManager.template.requestFailedSubWorkflow"
                                                          : "workflowTemplatesManager.template.requestFailed"
                                                  )
                                                : t(
                                                      props.isSubWorkflow
                                                          ? "workflowTemplatesManager.template.emptyStateMessageSubWorkflow"
                                                          : "workflowTemplatesManager.template.emptyStateMessage"
                                                  )}
                                        </TextBlock>
                                    </div>
                                </div>
                            )}
                        </div>
                    </FileDrop>
                </div>
                {tableState.cursor != null &&
                    tableState.workflowTemplatesData.length != 0 &&
                    requestFailureMessage === "" &&
                    (loading ? (
                        <LoadingIndicator small={true} />
                    ) : (
                        <button
                            className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                            onClick={() => {
                                fetchData(false, new AbortController());
                                usageStatisticsService.sendEvent({
                                    label: Label.WORKFLOW_TEMPLATES,
                                    action: Action.LOAD_MORE,
                                    category: Category.WORKFLOW_TEMPLATES,
                                });
                            }}
                            data-testid={testIds.common.primaryView.table.loadMoreButton}
                        >
                            {t("Common.loadMore")}
                        </button>
                    ))}
                <AlertDialog
                    isOpen={publishState.error}
                    hideModal={() => {
                        resetPublishState();
                    }}
                    type={"ERROR"}
                    primaryButton={{
                        action: () => {
                            handlePublishedChanged(
                                publishState.uuid,
                                publishState.name,
                                publishState.publishStatus,
                                publishState.setPublished,
                                publishState.setMenuHidden
                            );
                        },
                        text:
                            publishState.publishStatus === PublishStatus.PUBLISHED
                                ? t("workflowTemplatesManager.publish.publishAgain")
                                : t("workflowTemplatesManager.publish.unpublishAgain"),
                    }}
                    secondaryButton={{
                        action: () => {
                            resetPublishState();
                        },
                        text: t("Common.cancel"),
                    }}
                    loading={publishState.loading}
                    headingText={t("workflowTemplatesManager.publish.publishingFailed")}
                />
                <Modal
                    isOpen={uploadTemplateDialogVisible}
                    hideModal={hideUploadTemplateDialog}
                    modalTitle={uploadTemplateModalTitle}
                    titleIcon={uploadTemplateModalIcon}
                >
                    <ImportWorkflowsDialog
                        entityType={props.isSubWorkflow ? "SUB_WORKFLOW" : "TEMPLATE"}
                        fileList={uploadWorkflowFileList != null ? Array.from(uploadWorkflowFileList) : undefined}
                        onClose={hideUploadTemplateDialog}
                        service={templateService}
                        setModalTitle={setUploadTemplateModalTitle}
                        setModalTitleIcon={setUploadTemplateModalIcon}
                    />
                </Modal>
            </>
        );
    }
};

const PublishBadge = (props: {
    uuid: string;
    name: string;
    published: boolean;
    hasAuthority: boolean;
    handlePublishedChanged: (
        uuid: string,
        name: string,
        publishStatus: string,
        setPublished: React.Dispatch<React.SetStateAction<boolean>>,
        setMenuHidden: React.Dispatch<React.SetStateAction<boolean>>
    ) => void;
}): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [published, setPublished] = React.useState(props.published);
    const [menuHidden, setMenuHidden] = React.useState(false);
    const selectedTranslationText = published
        ? t("workflowTemplatesManager.template.published")
        : t("workflowTemplatesManager.template.unpublished");
    if (!props.hasAuthority) {
        return (
            <div className={style.alignLeft}>
                <StatusBadge
                    values={[{ status: published ? Status.SUCCESS : Status.NEUTRAL, title: selectedTranslationText }]}
                />
            </div>
        );
    }
    const selectedIconIndex = published ? 0 : 1;
    const menuItems: JSX.Element[] = [
        <MenuItemButton
            key="1"
            tabIndex={0}
            onClick={() => {
                // Prevent publishing again with the same value pointlessly.
                if (selectedIconIndex === 0) {
                    return;
                }
                setMenuHidden(true);
                usageStatisticsService.sendEvent({
                    label: Label.WORKFLOW_TEMPLATES,
                    action: Action.PUBLISH_TEMPLATE,
                    category: Category.WORKFLOW_TEMPLATES,
                });
                setPublished(true);
                props.handlePublishedChanged(
                    props.uuid,
                    props.name,
                    PublishStatus.PUBLISHED,
                    setPublished,
                    setMenuHidden
                );
            }}
            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.table.publishMenu.publishButton}
        >
            {t("workflowTemplatesManager.template.published")}
            {published ? <Checkmark color={theme.iconFillColor} /> : ""}
        </MenuItemButton>,
        <MenuItemButton
            key="2"
            tabIndex={0}
            onClick={() => {
                if (selectedIconIndex === 1) {
                    return;
                }
                setMenuHidden(true);
                usageStatisticsService.sendEvent({
                    label: Label.WORKFLOW_TEMPLATES,
                    action: Action.UNPUBLISH_TEMPLATE,
                    category: Category.WORKFLOW_TEMPLATES,
                });
                setPublished(false);
                props.handlePublishedChanged(
                    props.uuid,
                    props.name,
                    PublishStatus.UNPUBLISHED,
                    setPublished,
                    setMenuHidden
                );
            }}
            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.table.publishMenu.unpublishButton}
        >
            {t("workflowTemplatesManager.template.unpublished")}
            {!published ? <Checkmark color={theme.iconFillColor} /> : ""}
        </MenuItemButton>,
    ];
    return (
        <div className={style.alignLeft}>
            <ToggleablePanel
                title={""}
                icon={<TextWithTooltip text={selectedTranslationText}></TextWithTooltip>}
                menuType={"HEADER"}
                testId={testIds.workArea.workflowTemplatesManager.primaryView.table.publishMenu.button}
                hideChevron={false}
                chevronColor={published ? theme.successBadgeTextColor : theme.neutralBadgeTextColor}
                buttonClass={classNames(style.publishBadge, {
                    [statusStyle.statusSuccessful]: published,
                    [statusStyle.statusNeutral]: !published,
                })}
            >
                <Menu
                    className={classNames(style.publishMenu)}
                    data-testid={testIds.workArea.workflowTemplatesManager.primaryView.table.publishMenu.itself}
                    hidden={menuHidden}
                >
                    <ul>
                        {menuItems.map((item, key) => {
                            return <li key={key}>{item}</li>;
                        })}
                    </ul>
                </Menu>
            </ToggleablePanel>
        </div>
    );
};
export default connector(WorkflowTemplatesTable);
