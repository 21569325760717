import { Buffer } from "buffer";
import Clipboard from "clipboard";
import React from "react";
import { getUA, isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";

import Footer from "./Footer";
import Header from "./Header";
import style from "./page.scss";
import { exportFile } from "components/export/common";
import Heading from "components/typography/heading/Heading";
import { DownloadPageConfigurationResponse } from "domain/downloadPage";
import buttons from "styles/buttons.scss";

import defaultAppIcon from "assets/images/logo/blanccoDSAppIconPlaceholder.svg";

import testIds from "testIds.json";

interface Props {
    licenseKey: string;
    storeName?: string | null;
    custom?: string | null;
    configuration: DownloadPageConfigurationResponse;
}

const Page = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const downloadConfiguration = () => {
        if (props.storeName || props.custom) {
            let configString = `pin=${props.licenseKey}`;

            if (props.storeName) {
                configString += `|store_name=${props.storeName}`;
            }
            if (props.custom) {
                configString += `|custom=${props.custom}`;
            }

            return configString;
        }

        return props.licenseKey;
    };

    const encodedDownloadConfiguration = Buffer.from(downloadConfiguration()).toString("base64");
    new Clipboard("#downloadButton", {
        text: function () {
            return encodedDownloadConfiguration;
        },
    });

    const showDefaultAppIcon = (e: React.ChangeEvent<HTMLImageElement>) => {
        e.target.onerror = null;
        e.target.src = defaultAppIcon;
    };

    const downloadTxtFile = () => {
        if (props.licenseKey == "") {
            return true;
        } else {
            const file = new Blob([encodedDownloadConfiguration], { type: "text/plain" });
            exportFile(URL.createObjectURL(file), "SmartConnect.txt");
            return true;
        }
    };

    const downloadJpgImage = () => {
        if (props.licenseKey == "" || typeof props.configuration.licenseKeyFile === "undefined") {
            return true;
        }
        exportFile("data:image/jpg;base64," + props.configuration.licenseKeyFile, "SmartConnect.jpg");
        return true;
    };

    const downloadApp = async () => {
        if (props.licenseKey !== "") {
            let firebaseLink = "";
            if (isAndroid) {
                // If the Browser Agent includes HuaweiBrowser && HMSCore then use Huawei Firebase link.
                if (getUA.includes("HuaweiBrowser") && getUA.includes("HMSCore")) {
                    firebaseLink = props.configuration.huaweiFirebase;
                } else {
                    firebaseLink = props.configuration.androidFirebaseLink;
                }
            }

            if (isIOS) {
                firebaseLink = props.configuration.iosFirebaseLink;
            }

            if (firebaseLink && props.licenseKey != "") {
                if (isAndroid) {
                    if (
                        typeof props.configuration.licenseFileType === "undefined" ||
                        props.configuration.licenseFileType.toUpperCase() === "TXT"
                    ) {
                        downloadTxtFile();
                    } else {
                        downloadJpgImage();
                    }
                }

                if (props.storeName) {
                    firebaseLink = firebaseLink.concat(`&store_name=${props.storeName}`);
                }
                firebaseLink = firebaseLink.replace("{PIN_NUMBER}", props.licenseKey);
                window.location.href = firebaseLink;
            }
        }
    };

    return (
        <div className={style.downloadPage}>
            <Header logo={props.configuration.headerLogo} />
            <div className={style.container}>
                <Heading tag={"h1"} className={style.title} testId={testIds.workArea.downloadPage.title}>
                    {t("DownloadPage.downloadAppTitle")}
                </Heading>
                <div className={style.appIcon}>
                    <img
                        src={props.configuration.appIcon}
                        onError={showDefaultAppIcon}
                        data-testid={testIds.workArea.downloadPage.appIcon}
                    />
                    <Heading
                        tag={"div"}
                        variant={"SUBTITLE_1"}
                        className={style.appTitle}
                        testId={testIds.workArea.downloadPage.appTitle}
                    >
                        {props.configuration.title}
                    </Heading>
                </div>
                <div className={style.buttonContainer}>
                    <button
                        id="downloadButton"
                        onClick={downloadApp}
                        className={buttons.primaryButton}
                        data-testid={testIds.workArea.downloadPage.downloadAppButton}
                    >
                        {t("DownloadPage.downloadAppButton")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Page;
