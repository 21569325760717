import { FeatureFlags } from "flagged";

import { getObject, RepositoryKey } from "utils/repository";

export const FLAG_SUPPORT_REPORT_PATHS = "reportPaths";
export const FLAG_ROLES = "custom_roles";
export const FLAG_NEW_WORKFLOW_EDITOR = "new_workflow_editor";
export const FLAG_USER_GROUPS = "user_groups";
export const FLAG_TENANT_DELETE = "tenant_delete";
export const FLAG_DELETE_REPORT = "delete_reports";
export const FLAG_BMS_UI_CONFIG = "bms_ui_config";
export const FLAG_AUTHORITY_TARGETS = "authority_targets";
export const FLAG_COMPARE_REPORTS = "compare_reports";
export const FLAG_EXPORT_SELECTED_REPORTS = "export_selected_reports";
export const FLAG_PRINT_REPORT = "print_report";
export const FLAG_NEW_NAVIGATION = "new_navigation";
export const FLAG_LICENSE_ALERTS = "license_alerts";
export const FLAG_EXPORT_REPORTS_FILENAME = "export_reports_filename";
export const FLAG_CONNECTED_WORKFLOWS = "connected_workflows";
export const FLAG_ADVANCE_UI_SEARCH = "advance_ui_search";
export const FLAG_BLANCCO_TOKEN = "blancco_token";
export const FLAG_CHANGE_PASSWORD = "change_password";
export const FLAG_WORKFLOW_TEMPLATE = "workflow_template";
export const FLAG_CREATE_REPORT = "create_report";

// In production there won't be any overriding values in LocalStorage so this
// constant alone defines the flags there.
export const DEFAULT_FLAGS: FeatureFlags = {
    [FLAG_ROLES]: true,
    [FLAG_SUPPORT_REPORT_PATHS]: true,
    [FLAG_TENANT_DELETE]: true,
    [FLAG_USER_GROUPS]: true,
    [FLAG_DELETE_REPORT]: true,
    [FLAG_BMS_UI_CONFIG]: false,
    [FLAG_AUTHORITY_TARGETS]: true,
    [FLAG_COMPARE_REPORTS]: true,
    [FLAG_NEW_WORKFLOW_EDITOR]: true,
    [FLAG_EXPORT_SELECTED_REPORTS]: true,
    [FLAG_PRINT_REPORT]: true,
    [FLAG_NEW_NAVIGATION]: false,
    [FLAG_LICENSE_ALERTS]: true,
    [FLAG_EXPORT_REPORTS_FILENAME]: true,
    [FLAG_ADVANCE_UI_SEARCH]: true,
    [FLAG_BLANCCO_TOKEN]: true,
    [FLAG_CHANGE_PASSWORD]: true,
    [FLAG_CONNECTED_WORKFLOWS]: true,
    [FLAG_WORKFLOW_TEMPLATE]: false,
    [FLAG_CREATE_REPORT]: false,
};

class FeatureFlagService {
    private flags: FeatureFlags;

    constructor() {
        this.flags = Object.assign({}, DEFAULT_FLAGS);
    }

    getFlags(): FeatureFlags {
        return this.flags;
    }

    override(overriding: FeatureFlags): FeatureFlagService {
        this.flags = Object.assign({}, DEFAULT_FLAGS, overriding);
        return this;
    }
}

function readLocalStorageFlags(): FeatureFlags {
    return getObject<FeatureFlags>(RepositoryKey.FEATURE_FLAGS) ?? {};
}

export const featureFlagService = new FeatureFlagService().override(readLocalStorageFlags());
