import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./custom-report.scss";
import HddTemplate from "./HddTemplate";
import MonitorTemplate from "./MonitorTemplate";
import Button from "components/button/Button";
import { AddIcon } from "components/icons/AddIcon";
import Delete from "components/icons/Delete";
import Info from "components/icons/Info";
import { SubpageLayout } from "components/layout/subpage-layout/SubpageLayout";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import {
    CustomFieldFormValues,
    HddFormValues,
    MonitorFormValues,
    TemplateType,
    TemplateTypeEnum,
    templateTypes,
} from "domain/customReports";
import { CUSTOM_REPORT_FIELD_MAX_LENGTH } from "domain/globalConstants";
import { reportCreationService, ReportTemplate, ReportValue } from "services/report/erasure/ReportCreationService";
import { reportImportService } from "services/report/ReportImportService";
import { StoreState } from "store";
import form from "styles/form.scss";
import { Logger } from "utils/logging";

import testIds from "testIds.json";

const LOGGER = new Logger("CustomReportView");

interface Props {
    setVisible: (visible: boolean) => void;
    visible: boolean;
}

export interface FormValues extends MonitorFormValues, HddFormValues {
    template: string;
    company?: string;
    person?: string;
    customFields: CustomFieldFormValues[];
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const TEMPLATE_TYPE_LOCALIZATIONS: Record<TemplateType, string> = {
    Laptop: "ErasureReport.customReport.templateType.laptop",
    HDD: "ErasureReport.customReport.templateType.hdd",
    Mobiledevice: "ErasureReport.customReport.templateType.mobileDevice",
    Monitor: "ErasureReport.customReport.templateType.monitor",
    PC: "ErasureReport.customReport.templateType.pc",
};

// We this kind of generic function use of "any" is justified.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toList(container: any, prefix = ""): ReportValue[] {
    const list: ReportValue[] = [];
    Object.keys(container).forEach((key: string) => {
        if (!key) {
            return;
        }
        const value = container[key];
        if (value == null) {
            return;
        }
        const path = prefix === "" ? key : [prefix, key].join(".");
        if (typeof value === "string") {
            const trimmed = value.trim();
            if (trimmed !== "") {
                list.push({ path, value: trimmed });
            }
            return;
        }
        if (typeof value === "number") {
            list.push({ path, value });
            return;
        }
        if (typeof value === "object") {
            list.push(...toList(value, path));
        }
    });
    // Just to have deterministic results for tests.
    list.sort((first, second) => first.path.localeCompare(second.path));
    return list;
}

const CustomReportView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values) => {
        LOGGER.debug("Form values:", values);
        const valueList = toList(values).filter((value) => value.path !== "template");
        LOGGER.debug("Report values:", valueList);

        //BCC-3894 : Implement logic for passing data to API
        try {
            const xml = reportCreationService.createReport(values.template as ReportTemplate, valueList);
            if (xml) {
                createReport(xml);
            }
        } catch (error) {
            LOGGER.error("Failed to create XML report:", error);
        }
    };
    const [selectedCustomField, setSelectedCustomField] = React.useState<CustomFieldFormValues[]>([]);

    const addRow = () => {
        const newAdded: CustomFieldFormValues = {
            index: Math.max(-1, ...selectedCustomField.map((item) => item.index)) + 1,
            fieldName: "",
            fieldValue: "",
        };
        const customFieldRow = selectedCustomField.concat([newAdded]);
        setSelectedCustomField(customFieldRow);
    };

    const updateCustomFieldData = (
        customFieldData: CustomFieldFormValues,
        updatedField: string,
        e: React.ChangeEvent<HTMLInputElement>
    ): CustomFieldFormValues[] => {
        return selectedCustomField.map((each) => {
            if (each.index === customFieldData.index) {
                if (updatedField == "fieldName") {
                    each.fieldName = e.target.value;
                } else {
                    each.fieldValue = e.target.value;
                }
            }
            return each;
        });
    };

    const removeRow = (index: number) => {
        setSelectedCustomField(selectedCustomField.filter((each) => each.index !== index));
    };

    return (
        <SubpageLayout
            visible={props.visible}
            title={t("ErasureReport.customReport.buttonTitle")}
            buttons={
                <>
                    <Button variant={"PRIMARY"} type="submit" form="customReportForm">
                        {t("Common.create")}
                    </Button>
                </>
            }
        >
            <Formik
                validateOnBlur={true}
                initialValues={{
                    template: "HDD",
                    customFields: [],
                }}
                onSubmit={submitHandler}
                validateOnChange={false}
            >
                {(formikProps) => (
                    <Form id="customReportForm">
                        <>
                            <div>
                                <div className={style.infoDiv}>
                                    <span className={classNames(form.infoIcon, style.infoIcon)}>
                                        <Info
                                            borderColor={props.theme.contentBackgroundColor}
                                            color={props.theme.iconFillColor}
                                        />
                                    </span>
                                    <p className={style.infoMessage}>{t("ErasureReport.customReport.infoMessage")}</p>
                                </div>
                                <Heading tag="div" variant="SUBTITLE_1">
                                    {t("ErasureReport.customReport.templateSpecifications")}
                                </Heading>
                                <div className={form.formFields}>
                                    <label htmlFor="template" className={classNames(form.label)}>
                                        {t("ErasureReport.customReport.customReportTemplate.title")}
                                    </label>
                                    <select
                                        id="template"
                                        name="template"
                                        className={classNames(form.select, form.fixedWidthInput)}
                                        onChange={formikProps.handleChange}
                                        value={formikProps.values.template}
                                        data-testid={testIds.workArea.report.viewManualReportCreation.templateSelect}
                                    >
                                        {templateTypes.map((value: TemplateType) => {
                                            return (
                                                <option key={value} value={value}>
                                                    {t(TEMPLATE_TYPE_LOCALIZATIONS[value])}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {formikProps.values.template === TemplateTypeEnum.HDD && (
                                    <HddTemplate formikProps={formikProps} />
                                )}
                                {formikProps.values.template === TemplateTypeEnum.Monitor && (
                                    <MonitorTemplate formikProps={formikProps} />
                                )}
                                <Heading tag="div" variant="SUBTITLE_1">
                                    {t("ErasureReport.customReport.generalDetails.title")}
                                </Heading>
                                <div
                                    className={form.formFields}
                                    data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
                                >
                                    <label htmlFor="company" className={form.label}>
                                        {t("ErasureReport.customReport.generalDetails.company")}
                                    </label>
                                    <input
                                        id="company"
                                        className={classNames(form.input, form.fixedWidthInput)}
                                        maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                                        data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                                        data-path="blancco_data.description.description_entries.company_information.business_name"
                                        onChange={(event) => {
                                            const trimmedValue = event.target.value.trim();
                                            formikProps.setFieldValue("company", trimmedValue);
                                        }}
                                    />
                                </div>
                                <div
                                    className={form.formFields}
                                    data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
                                >
                                    <label htmlFor="person" className={form.label}>
                                        {t("ErasureReport.customReport.generalDetails.person")}
                                    </label>
                                    <input
                                        id="person"
                                        className={classNames(form.input, form.fixedWidthInput)}
                                        maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                                        data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                                        data-path="blancco_data.description.description_entries.company_information.erasure_person"
                                        onChange={(event) => {
                                            const trimmedValue = event.target.value.trim();
                                            formikProps.setFieldValue("person", trimmedValue);
                                        }}
                                    />
                                </div>
                                <Heading tag="div" variant="SUBTITLE_1">
                                    {t("ErasureReport.customReport.customFields.title")}
                                </Heading>
                                <div>
                                    {selectedCustomField.length > 0 && (
                                        <StaticTable
                                            headers={[
                                                {
                                                    value: t("ErasureReport.customReport.customFields.fieldName"),
                                                },
                                                {
                                                    value: t("ErasureReport.customReport.customFields.fieldValue"),
                                                },
                                                {
                                                    value: "",
                                                },
                                            ]}
                                            cells={selectedCustomField.map((each) => {
                                                return [
                                                    <div key={"fieldName" + each.index}>
                                                        <input
                                                            className={classNames(form.input)}
                                                            type="text"
                                                            onChange={(e) => {
                                                                setSelectedCustomField(
                                                                    updateCustomFieldData(each, "fieldName", e)
                                                                );
                                                            }}
                                                            value={each.fieldName}
                                                            data-testid={
                                                                testIds.workArea.report.viewManualReportCreation
                                                                    .customTable.nameInput.itself
                                                            }
                                                        />
                                                        <div
                                                            className={form.error}
                                                            data-testid={
                                                                testIds.workArea.report.viewManualReportCreation
                                                                    .customTable.nameInput.errorLabel
                                                            }
                                                        >
                                                            <ErrorMessage name="fieldName" />
                                                        </div>
                                                    </div>,
                                                    <div key={"fieldValue" + each.index}>
                                                        <input
                                                            className={classNames(form.input)}
                                                            type="text"
                                                            onChange={(e) => {
                                                                setSelectedCustomField(
                                                                    updateCustomFieldData(each, "fieldValue", e)
                                                                );
                                                            }}
                                                            value={each.fieldValue}
                                                            data-testid={
                                                                testIds.workArea.report.viewManualReportCreation
                                                                    .customTable.valueInput.itself
                                                            }
                                                        />
                                                        <div
                                                            className={form.error}
                                                            data-testid={
                                                                testIds.workArea.report.viewManualReportCreation
                                                                    .customTable.valueInput.errorLabel
                                                            }
                                                        >
                                                            <ErrorMessage name="fieldValue" />
                                                        </div>
                                                    </div>,
                                                    <div key={each.index}>
                                                        <div
                                                            key={"deleteRow" + each.index}
                                                            className={style.margin}
                                                            onClick={() => {
                                                                removeRow(each.index);
                                                            }}
                                                            data-testid={
                                                                testIds.workArea.report.viewManualReportCreation
                                                                    .customTable.removeButton
                                                            }
                                                        >
                                                            <Delete
                                                                noBackground={true}
                                                                strokeColor={props.theme.errorIconColor}
                                                            />
                                                        </div>
                                                    </div>,
                                                ];
                                            })}
                                            testId={testIds.workArea.report.viewManualReportCreation.customTable.itself}
                                        />
                                    )}
                                </div>
                                <span className={classNames(style.addMoreCustomFields)}>
                                    <div
                                        onClick={addRow}
                                        className={style.link}
                                        data-testid={
                                            testIds.workArea.report.viewManualReportCreation.customTable.addButton
                                        }
                                    >
                                        <AddIcon
                                            color={props.theme.contentBackgroundColor}
                                            linecolor={props.theme.iconFillColor}
                                        />
                                        <span className={style.addMoreButton}>
                                            {t("ErasureReport.customReport.customFields.addCustomField")}
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </>
                    </Form>
                )}
            </Formik>
        </SubpageLayout>
    );
};

export default connector(CustomReportView);

export async function createReport(xml: string) {
    const initializeResponse = await reportImportService.initializeJob(false);
    const uploadUrl = (await reportImportService.fetchUploadUrls(initializeResponse.jobId, ["created.xml"])).urls[0];
    await fetch(uploadUrl.url, {
        method: "PUT",
        body: new Blob([xml], {
            type: "application/xml",
        }),
    });
    LOGGER.debug("Report uploaded, start validation in the backend.");

    await reportImportService.validateReports(initializeResponse.jobId, {
        totalReports: 1,
        bucketKeys: [uploadUrl.key],
    });

    const success = await waitForVerification(initializeResponse.jobId);
    if (!success) {
        return;
    }
    LOGGER.debug("Validation successful, start import proper.");

    const importAbort = new AbortController();
    await reportImportService.importReports(initializeResponse.jobId, importAbort);
    LOGGER.debug("Import called without errors.");
}

async function waitForVerification(jobId: string, waitExponent?: number): Promise<boolean> {
    waitExponent ??= 1;
    const response = await reportImportService.fetchReportImportJob(jobId, [], "NORMAL", "IMPORT");
    if (response.status === "WAITING") {
        const waitMillis = 1_000 ** waitExponent;
        await new Promise((resolve) => setTimeout(resolve, waitMillis));
        // 1000 ^ 1   ==   1s
        // 1000 ^ 1.1 ==  ~2s
        // 1000 ^ 1.2 ==  ~4s
        // 1000 ^ 1.3 ==  ~8s
        // 1000 ^ 1.4 == ~16s
        //               ----
        // sum           ~31s
        return await waitForVerification(jobId, waitExponent < 1.5 ? waitExponent + 0.1 : waitExponent);
    }
    if (response.status === "VALIDATED") {
        return true;
    }
    LOGGER.error("Report failed validation.");
    return false;
}
