import classNames from "classnames";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./custom-report.scss";
import { CapacityUnit, capacityUnits, HddFormValues } from "domain/customReports";
import { CUSTOM_REPORT_FIELD_MAX_LENGTH } from "domain/globalConstants";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface HddTemplateProps {
    formikProps: FormikProps<HddFormValues>;
}

function convertToBytes(value: number, unit: CapacityUnit): number {
    switch (unit) {
        case "KB":
            return value * 1024;
        case "MB":
            return value * 1024 * 1024;
        case "GB":
            return value * 1024 * 1024 * 1024;
        case "TB":
            return value * 1024 * 1024 * 1024 * 1024;
        default:
            throw new Error("Invalid unit");
    }
}

const HddTemplate = ({ formikProps }: HddTemplateProps): JSX.Element => {
    const { t } = useTranslation();
    const { values, setFieldValue } = formikProps;

    const [error, setError] = useState<string>("");

    const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const numericValue = Number(value);
        if (numericValue < 0) {
            setError(t("ErasureReport.customReport.customReportTemplate.validationMessage"));
            setFieldValue("blancco_hardware_report.disks.disk.capacity", "");
        } else {
            setFieldValue("blancco_hardware_report.disks.disk.capacity", numericValue);
            setError("");
        }
    };

    const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedUnit = event.target.value as CapacityUnit;
        const currentCapacity = values.capacity || 0;
        const convertedValue = convertToBytes(currentCapacity, selectedUnit);
        setFieldValue("blancco_hardware_report.disks.disk.capacity", convertedValue);
        setFieldValue("capacityUnit", selectedUnit);
    };
    const handleFieldChange = (datapath: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const trimmedValue = event.target.value.trim();
        setFieldValue(datapath, trimmedValue);
    };

    return (
        <>
            <div className={form.formFields}>
                <label htmlFor="capacity" className={form.label}>
                    {t("ErasureReport.customReport.customReportTemplate.capacity")}
                </label>
                <input
                    id="capacity"
                    type="number"
                    className={form.input}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    onChange={handleCapacityChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="blancco_data.blancco_hardware_report.disks.disk.capacity"
                />
                <select
                    id="capacityUnit"
                    onChange={handleUnitChange}
                    className={classNames(form.select, style.unitSelect)}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldSelect}
                >
                    {capacityUnits.map((unit) => (
                        <option key={unit} value={unit}>
                            {unit}
                        </option>
                    ))}
                </select>
                {error && <div className={form.error}>{error}</div>}
            </div>
            <div className={form.formFields}>
                <label htmlFor="vendor" className={form.label}>
                    {t("ErasureReport.customReport.customReportTemplate.vendor")}
                </label>
                <input
                    id="vendor"
                    className={classNames(form.input, form.fixedWidthInput)}
                    onChange={handleFieldChange("blancco_hardware_report.disks.disk.vendor")}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="blancco_data.blancco_hardware_report.disks.disk.vendor"
                />
            </div>
            <div className={form.formFields}>
                <label htmlFor="model" className={form.label}>
                    {t("ErasureReport.customReport.customReportTemplate.model")}
                </label>
                <input
                    id="model"
                    className={classNames(form.input, form.fixedWidthInput)}
                    onChange={handleFieldChange("blancco_hardware_report.disks.disk.model")}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="blancco_data.blancco_hardware_report.disks.disk.model"
                />
            </div>
            <div className={form.formFields}>
                <label htmlFor="serialNumber" className={form.label}>
                    {t("ErasureReport.customReport.monitor.serialNumber")}
                </label>
                <input
                    id="serialNumber"
                    className={classNames(form.input, form.fixedWidthInput)}
                    onChange={handleFieldChange("blancco_hardware_report.disks.disk.serial")}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="blancco_data.blancco_hardware_report.disks.disk.serial"
                />
            </div>
        </>
    );
};

export default HddTemplate;
