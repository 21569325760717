import classNames from "classnames";
import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { MonitorFormValues } from "domain/customReports";
import { CUSTOM_REPORT_FIELD_MAX_LENGTH } from "domain/globalConstants";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface MonitorTemplateProps {
    formikProps: FormikProps<MonitorFormValues>;
}

const MonitorTemplate = ({ formikProps }: MonitorTemplateProps): JSX.Element => {
    const { t } = useTranslation();
    const { values, handleChange } = formikProps;

    return (
        <>
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor="aspectRatio" className={form.label}>
                    {t("ErasureReport.customReport.monitor.aspectRatio")}
                </label>
                <input
                    id="aspectRatio"
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    value={values.aspectRatio}
                    onChange={handleChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="user_data.fields.usercreatedreport.aspect_ratio"
                />
            </div>
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor="comments" className={form.label}>
                    {t("ErasureReport.customReport.monitor.comments")}
                </label>
                <input
                    id="comments"
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    value={values.comments}
                    onChange={handleChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="user_data.fields.usercreatedreport.comments"
                />
            </div>
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor="make" className={form.label}>
                    {t("ErasureReport.customReport.monitor.make")}
                </label>
                <input
                    id="make"
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    value={values.make}
                    onChange={handleChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="user_data.fields.usercreatedreport.make"
                />
            </div>
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor="model" className={form.label}>
                    {t("ErasureReport.customReport.monitor.model")}
                </label>
                <input
                    id="model"
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    value={values.model}
                    onChange={handleChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="user_data.fields.usercreatedreport.model"
                />
            </div>
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor="resolution" className={form.label}>
                    {t("ErasureReport.customReport.monitor.resolution")}
                </label>
                <input
                    id="resolution"
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    value={values.resolution}
                    onChange={handleChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="user_data.fields.usercreatedreport.resolution"
                />
            </div>
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor="serialNumber" className={form.label}>
                    {t("ErasureReport.customReport.monitor.serialNumber")}
                </label>
                <input
                    id="serialNumber"
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    value={values.serialNumber}
                    onChange={handleChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="user_data.fields.usercreatedreport.serial_number"
                />
            </div>
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor="size" className={form.label}>
                    {t("ErasureReport.customReport.monitor.size")}
                </label>
                <input
                    id="size"
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    value={values.size}
                    onChange={handleChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="user_data.fields.usercreatedreport.size"
                />
            </div>
            <div
                className={form.formFields}
                data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
            >
                <label htmlFor="type" className={form.label}>
                    {t("ErasureReport.customReport.monitor.type")}
                </label>
                <input
                    id="type"
                    className={classNames(form.input, form.fixedWidthInput)}
                    maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                    value={values.type}
                    onChange={handleChange}
                    data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                    data-path="user_data.fields.usercreatedreport.type"
                />
            </div>
        </>
    );
};

export default MonitorTemplate;
